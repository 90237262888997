 <template>
  <div class="content_box content-box">
    <div class="innovation-capability-index">
      <!---->
      <div class="all-report">
        <!---->
        <div class="all-report-content">
          <!-- <div class="company-date">
            <span>报告出具方：博士云服务平台</span
            ><span class="date">出具日期：NaN-NaN-NaN</span>
          </div> -->
          <!-- <div class="basic flex-center">
            <div class="index-num flex-center">
              <span class="num">60.8</span><span>创新指数</span>
            </div>
            <div class="msg-content flex-center">
              <h4 class="name">牙博士医疗控股集团有限公司</h4>
              <div class="label-value flex-center">
                <div class="label-value-item">
                  <span class="label">法人代表：</span><span></span>
                </div>
                <div class="label-value-item">
                  <span class="label">地区：</span><span></span>
                </div>
              </div>
            </div>
            <div class="export-pdf flex-center">
              <div class="export-btn">导出PDF&gt;</div>
            </div>
          </div> -->

          <div class="assess-item">
            <div class="assess-title">
              <span class="text">评估结果</span
              ><!---->
            </div>
            <ul class="assess-table">
              <li class="assess-th">
                <ul class="flex-center">
                  <li class="assess-td-1">名称</li>
                  <li class="assess-td-2">得分</li>
                  <li class="assess-td-3">能力情况</li>
                  <li class="assess-td-4">说明</li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-0">
                <ul class="flex-center">
                  <li class="assess-td-1 name">知识产权能力</li>
                  <li class="assess-td-2 sorce">19.5</li>
                  <li class="assess-td-3 icons">
                    <el-rate v-model="value1" disabled></el-rate>
                  </li>
                  <li class="assess-td-4 desc">
                    您已具备申请高新技术企业的条件。知识产权数量已达标，建议持续申请与主营产品相关的知识产权，为三年后的重新认定准备，增加知识产权的方式有购买/转让/自主申请
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-1">
                <ul class="flex-center">
                  <li class="assess-td-1 name">研发管理能力</li>
                  <li class="assess-td-2 sorce">17.5</li>
                  <li class="assess-td-3 icons">
                    <el-rate v-model="value2" disabled></el-rate>
                  </li>
                  <li class="assess-td-4 desc">
                    建议加强企业研发机构建设①完善研发管理制度等；②研发场地、设备设施等配套
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-0">
                <ul class="flex-center">
                  <li class="assess-td-1 name">科技财税能力</li>
                  <li class="assess-td-2 sorce">7.8</li>
                  <li class="assess-td-3 icons">
                    <el-rate v-model="value3" disabled> </el-rate>
                  </li>
                  <li class="assess-td-4 desc">
                    成长性得分为7.8分（销售收入增长率得分为0.0，净资产增长率得分为7.8），财务增长性一般
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-1">
                <ul class="flex-center">
                  <li class="assess-td-1 name">科技成果能力</li>
                  <li class="assess-td-2 sorce">16</li>
                  <li class="assess-td-3 icons">
                    <el-rate v-model="value4" disabled> </el-rate>
                  </li>
                  <li class="assess-td-4 desc">
                    转化能力较强，建议通过丰富证明材料强化科技成果转化质量（证明材料：知识产权证书、销售合同、销售发票、用户使用报告、成果查新、产品检测报告、产品说明书等）；
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="assess-item">
            <div class="assess-title">
              <span class="text">评估建议</span>
            </div>
            <div class="assess-tip">
              从研发机构、科研立项、科技成果、科技财税、研发团队、知识产权六个方面梳理企业的科技创新体系，从而为企业提供全面的科技创新能力体系建设解决方案。
            </div>
            <ul class="assess-table">
              <li class="assess-th only">体系建设</li>
              <li class="assess-tr assess-tr-0">
                <ul class="flex-center">
                  <li class="assess-td-1 name">1.研发管理机构系统</li>
                  <li class="assess-td-4 desc">
                    ①发展规划：明确的技术路线和研发计划；
                    ②治理结构：设立研发机构，完善支撑体系；
                    ③运行管理：知识产权、人才培养、创新激励、成果转化；
                    ④合作交流：与高水平科研机构进行合作交流，优化配置资源。
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-1">
                <ul class="flex-center">
                  <li class="assess-td-1 name">2.科研立项管理系统</li>
                  <li class="assess-td-4 desc">
                    ①加强产学研合作；
                    ②年度项目规划，规范内部项目立项制度，内部立项要有全套的科学的立项证明（立项报告、研发投入证明材料、结题报告等）；
                    ③在完善科研立项管理系统过程中积极申报相关的科技项目，不断争取外围资源支持。
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-0">
                <ul class="flex-center">
                  <li class="assess-td-1 name">3.科技成果管理系统</li>
                  <li class="assess-td-4 desc">
                    ①通过“成果鉴定、行业鉴定、产业化证明及专家判断”评价成果所形成的产品、服务、样品、样机的产业发展、技术创新、市场前景、效益分析；
                    ②高新技术产品认定、标准/论文、行业鉴定、科技成果登记/鉴定等；
                    ③将科技成果转化变成常规化的工作，实现转化信息反馈与立项融合，依靠科技成果转化体系提高我院的技术开发和工程化集成能力，保障科技成果不断实现商品化。
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-1">
                <ul class="flex-center">
                  <li class="assess-td-1 name">4.科技财税管理系统</li>
                  <li class="assess-td-4 desc">
                    ①对总资产和销售额这些成长性指标的分析，资产负债表、利润及利润分配、现金流量表等研究，进一步谋划我院的发展；
                    ②对科技人员与研发人员比例、研发经费占比、高新技术产品占比等，引导和鼓励我院按照创新目标加强建设；
                    ③帮助我院建立“指标合理、账务规范、票据真实、审计备案”科技财务规范。
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-0">
                <ul class="flex-center">
                  <li class="assess-td-1 name">5.研发团队管理系统</li>
                  <li class="assess-td-4 desc">
                    ①团队结构：核心人员、学历结构、职称结构优化；
                    ②创新成果：获科技成果奖数、已完成科研项目数、获知识产权数、发表论文论著数；
                    ③将研发团队培育和人才/团队项目申请变成常规化的工作，实现团队管理与科技创新发展融合。
                  </li>
                </ul>
              </li>
              <li class="assess-tr assess-tr-1">
                <ul class="flex-center">
                  <li class="assess-td-1 name">6.知识产权管理系统</li>
                  <li class="assess-td-4 desc">
                    ①技术先进性：明确的技术路线和研发计划，实施专利战略；
                    ②发明专利拥有量：近三年内通过自主研发、受让、受赠、并购、独占等方式，对主要产品的核心技术拥有发明专利；
                    ③品牌保护：包括商标设计监管、商标申请注册、商标使用策略与监管以及商标权维护等。
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="actions flex-center">
            <!-- <div class="action-item">&lt;重新评估</div>
            <div class="action-item">一键咨询&gt;</div> -->
            <el-button type="primary" round @click="again">重新评估</el-button>
          </div>
          <!-- <div class="footer-img">
            <img
              src="/pc/images/newifuhua/innovationCapability/index-3-footer.png"
              alt=""
            />
          </div> -->
        </div>
        <!-- <div class="footer-tip">
          <div class="tip-title">备注说明</div>
          <p class="tip-grap">
            Ⅰ类知识产权=发明专利+植物新品种+国家级农作物新品种+国家新药+国家一级中药保护品种+集成电路布图设计专有权
          </p>
          <p class="tip-grap">
            Ⅱ类知识产权=实用新型专利+外观设计专利+软件著作权
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: 3,
      value2: 4,
      value3: 3.5,
      value4: 3,
    };
  },

  created() {},
  methods: {
      again() {
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="less" scoped>
.content_box {
//   height: calc(100vh - 66px); 
  overflow-y: auto;
  overflow-x: hidden;
  .innovation-capability-index {
    line-height: 1.3;
    padding: 20px;
    .all-report {
      background: url(../../../assets/img/huiyuanzhongxin/index-3-title-bg.png)
        no-repeat;
      background-size: contain;
      background-color: #fff;
      overflow: hidden;
      .all-report-content {
        padding: 24px 42px 25px;
        margin: 100px 8% 0;
        background: #fff;
        // -webkit-box-shadow: 0 10px 30px 0 rgb(23 71 112 / 10%);
        box-shadow: 0 10px 30px 0 rgb(23 71 112 / 10%);
        position: relative;
        // .company-date {
        //   color: #999;
        //   font-size: 12px;
        //   margin-bottom: 15px;
        // }
        .flex-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        //         .basic  {
        //     margin-bottom: 23px;
        // }
        .assess-item {
          .assess-title {
            color: #333;
            font-size: 18px;
            text-align: center;
            position: relative;
            .text {
              z-index: 2;
              position: relative;
            }
          }
          .assess-title:after {
            display: block;
            content: "";
            width: 81px;
            height: 8px;
            margin-right: 40px;
            background: #78de93;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -40px;
          }
          .assess-table {
            margin-top: 16px;
            border: 2px solid #4a84f7;
            font-size: 14px;
            .assess-th {
              background: #2d5dbb;
              padding: 0 21px 0 17px;
              height: 46px;
              line-height: 46px;
              color: #fff;
              font-size: 14px;
              .assess-td-1 {
                width: 107px;
                flex: none;
              }
              .assess-td-2 {
                width: 54px;
                flex: none;
              }
              .assess-td-3 {
                width: 130px;
                flex: none;
              }
              .assess-td-4 {
                -webkit-box-flex: 1;
                flex: 1;
              }
            }
            .assess-tr.assess-tr-0 {
              background: #e6eeff;
            }
            .assess-tr.assess-tr-1 {
              background: #dbe2fb;
            }
            .assess-tr {
              padding: 20px 21px 20px 17px;
              .name {
                color: #2d5dbb;
              }
              .sorce {
                color: #f45c5c;
              }
              .assess-td-1 {
                width: 107px;
                flex: none;
              }
              .assess-td-2 {
                width: 54px;
                flex: none;
              }
              .assess-td-3 {
                width: 130px;
                flex: none;
              }
              .assess-td-4 {
                -webkit-box-flex: 1;
                flex: 1;
              }
            }
          }
          .assess-tip {
            text-align: center;
            margin-top: 26px;
            padding: 0 25px;
            color: #333;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .assess-item + .assess-item {
          margin-top: 40px;
          .assess-td-1 {
            width: 157px !important;
          }
        }
        .actions {
          margin-top: 23px;
        }
      }
    }
  }
}
</style>